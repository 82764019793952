import { createFeatureSelector, createSelector } from '@ngrx/store'
import { StateApp } from '@shared/state/reducers/app.reducer'
import { UserType } from '@modules/site/interfaces/site'

export const selectAppFeature = createFeatureSelector<StateApp>('app')
export const selectAppLoading = createSelector(selectAppFeature, state => state.loading)
export const selectAppUser = createSelector(selectAppFeature, state => state.user)
export const selectAppUserIsFinance = createSelector(
  selectAppFeature,
  state => [UserType.FINANCE_ADMIN].includes(state?.user?.role as UserType) as boolean
)
export const selectAppUserIsAdmin = createSelector(
  selectAppFeature,
  state => [UserType.ADMIN].includes(state?.user?.role as UserType) as boolean
)
export const selectAppUserIsSuperAdmin = createSelector(
  selectAppFeature,
  state => [UserType.SUPERADMIN, UserType.SAAS_SUPERADMIN].includes(state?.user?.role as UserType) as boolean
)

export const selectAppShowSectionFinanceActive = createSelector(
  selectAppFeature,
  state =>
    [UserType.SUPERADMIN, UserType.FINANCE_ADMIN].includes(state?.user?.role as UserType) &&
    (state?.company?.is_credit_active as boolean)
)

export const selectAppShowSectionFinance = createSelector(selectAppFeature, state =>
  [UserType.SUPERADMIN, UserType.FINANCE_ADMIN].includes(state?.user?.role as UserType)
)

export const selectAppUserRole = createSelector(selectAppFeature, state => state?.user?.role as UserType)
export const selectAppUserRoleType = createSelector(selectAppFeature, state => {
  if (!state?.user?.role) return undefined
  switch (state?.user?.role) {
    case UserType.SUPERADMIN:
      return UserType.SUPERADMIN as UserType
    default:
      return UserType.ADMIN as UserType
  }
})
export const selectAppCompany = createSelector(selectAppFeature, state => state.company)
export const selectAppCompanyCreditActive = createSelector(selectAppFeature, state => state.company?.is_credit_active)
export const selectAppCatalogs = createSelector(selectAppFeature, state => state.catalogs)
export const selectAppCompanyCreditSettings = createSelector(selectAppFeature, state => state.credit_settings)
